import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import { DataGrid, GridToolbar, GridColDef } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';

import SectionHeader from 'components/layout/SectionHeader';
import useCurrentUser from 'hooks/useCurrentUser';

import { DeleteIcon } from '../../assets/icons';
import ProjectSelector from '../../components/common/ProjectSelector';
import UserFiltersComponent from '../../components/common/UserFiltersComponent';
import { useGetAlertsQuery, useArchiveAlertMutation } from '../../services/api/alert';
import { useGetFilterableUsersQuery } from '../../services/api/user.api';
import { Alert } from '../../services/types/alert';
import type { Project } from '../../services/types/project';
import COMPANIES_TO_SHOW_ASSIGNED_USER from '../../utils/assignableUserConstants';

interface PaginationModel {
  page: number;
  pageSize: number;
}

const Alerts = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const queryParams = new URLSearchParams(window.location.search);
  const queryProjectId = queryParams.get('projectId');
  const [projectParamId, setProjectParamId] = useState<string>(queryProjectId || '');
  const [userFiltered, setUserFiltered] = useState<string>('');

  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 100,
  });

  const { data, refetch } = useGetAlertsQuery({
    projectId: projectParamId,
    userFiltered,
    limit: paginationModel.pageSize ?? 100,
    offset: paginationModel.page * paginationModel.pageSize,
  });

  const [archiveAlert] = useArchiveAlertMutation();
  const { data: filterableUsersData, refetch: userRefetch } = useGetFilterableUsersQuery({});

  const handleDelete = (id: number) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('¿Estás seguro de que deseas eliminar esta alerta?')) {
      archiveAlert({ alertId: id })
        .unwrap()
        .then(() => refetch())
        .catch(() => alert(`No se pudo eliminar`)); // eslint-disable-line no-alert
    }
  };

  useEffect(() => {
    queryParams.delete('projectId');
    // save projectId in query params
    if (projectParamId) {
      queryParams.set('projectId', projectParamId);
      navigate(`?${queryParams.toString()}`);
    }

    refetch().catch((e) => console.error(e)); // eslint-disable-line no-console
    userRefetch().catch((e) => console.error(e)); // eslint-disable-line no-console
  }, [projectParamId]);

  useEffect(() => {
    refetch().catch((e) => console.error(e)); // eslint-disable-line no-console
  }, [userFiltered, paginationModel.page, paginationModel.pageSize]);

  const columns: GridColDef<Alert>[] = [
    {
      field: 'clientId',
      headerName: 'Cliente',
      width: 90,
      filterable: true,
      renderCell: (params) => (
        <a href={`/clients/${params.value}`} target="_blank" rel="noreferrer">
          {params.value}
        </a>
      ),
    },
    {
      field: 'client',
      headerName: 'Nombre',
      width: 150,
      valueGetter: (value) => {
        const client = value as { firstName: string | null; lastName: string | null; motherLastName: string | null };
        return `${client.firstName || ''} ${client.lastName || ''} ${client.motherLastName || ''}`;
      },
    },
    {
      field: 'identifier',
      headerName: 'Tipo de alerta',
      width: 300,
    },
    {
      field: 'messageText',
      headerName: 'Mensaje',
      width: 575,
    },
    {
      field: 'createdAt',
      headerName: 'Fecha',
      width: 250,
      renderCell(params) {
        const dateValue = new Date(params.row.createdAt);
        return dateValue.toLocaleString('es-CL', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        });
      },
    },
    {
      field: 'archive',
      headerName: 'Archivar',
      width: 130,
      sortable: false,
      valueGetter: () => 'Resolver',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => handleDelete(params.row.id)}
        >
          Resolver
        </Button>
      ),
    },
  ];

  if (data?.alerts && data?.alerts[0]?.projectId && currentUser?.isAdmin) {
    const adminColumns: GridColDef<Alert>[] = [
      {
        field: 'id',
        headerName: 'Id',
        width: 70,
      },
      {
        field: 'Project',
        headerName: 'Inmobiliaria',
        width: 130,
        valueGetter: (value) => (value as { name: string })?.name || '',
      },
      {
        field: 'subProject',
        headerName: 'Proyecto',
        width: 85,
        valueGetter: (_params, row) => (row as Alert | undefined)?.client?.subProjectId || '',
      },
    ];
    columns.unshift(...adminColumns);
  }

  if (COMPANIES_TO_SHOW_ASSIGNED_USER.includes(currentUser?.projectId || 0) || currentUser?.isAdmin) {
    const executiveColumn: GridColDef<Alert>[] = [
      {
        field: 'executiveMail',
        headerName: 'Ejecutivo',
        width: 150,
        valueGetter: (_params, row) => row?.client?.executiveInformation?.executiveEmail || '',
      },
    ];
    columns.splice(columns.length - 1, 0, ...executiveColumn);
  }

  const handleProjectSelect = (project: Project | null) => {
    if (project) {
      setProjectParamId(`${project.id}`);
    }
  };

  return (
    <Box>
      {currentUser?.isAdmin && (
        <ProjectSelector
          onSelect={handleProjectSelect}
          projectId={projectParamId ? Number(projectParamId) : undefined}
          onlyMasterProjects
        />
      )}
      <SectionHeader text="Lista de Alertas" button={null} />
      <Box sx={{ mb: '1%' }}>
        <UserFiltersComponent
          filterableUsersData={filterableUsersData || null}
          userFilterProps={{ userFiltered, setUserFiltered }}
          onlyContactedProps={null}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 4,
          p: 2,
        }}
      >
        {data?.alerts && (
          <DataGrid
            rows={data.alerts || []}
            columns={columns}
            slots={{
              toolbar: GridToolbar,
            }}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              color: 'Black',
              height: 'calc(100vh - 120px)',
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-row.Mui-selected': {
                backgroundColor: '#e8f0fe',
              },
              border: 'none',
            }}
            pageSizeOptions={[100]}
            paginationModel={paginationModel}
            onPaginationModelChange={(params) => {
              setPaginationModel({ page: params.page, pageSize: params.pageSize });
            }}
            rowCount={data.total || 0}
            paginationMode="server"
          />
        )}
      </Box>
    </Box>
  );
};

export default Alerts;
