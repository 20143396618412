/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { SecondaryBtn } from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import useMsal from 'hooks/useMsal';
import Error from 'pages/Error/ErrorAlert';
import { useSignInUserMutation } from 'services/api/user.api';
import { AZURE_AD_DOMAINS } from 'utils/azureADConstants';
import getReCaptchaToken from 'utils/getReCaptchaToken';
import validateEmail from 'utils/validations/validateEmail';

import { signInUser } from '../../redux/slices/user.slice';
import styles from './SignIn.module.scss';

const SignIn: FC = () => {
  const [signIn, { data, isLoading, isSuccess, isError, error }] = useSignInUserMutation();
  const [emailInput, setEmailInput] = React.useState('');
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const dispatch = useDispatch();
  const msalInstance = useMsal();

  const shouldUseAzureAD = (email: string): boolean => {
    const domain = email.split('@')[1];
    return AZURE_AD_DOMAINS.includes(domain);
  };

  const handleAzureADLogin = async () => {
    try {
      await msalInstance.initialize();
      // First check if there's an interaction in progress
      if (msalInstance.getActiveAccount()) {
        await msalInstance.logoutPopup();
      }

      await msalInstance.handleRedirectPromise();

      const response = await msalInstance.loginPopup({
        scopes: [`api://${import.meta.env.VITE_REACT_APP_AZURE_CLIENT_ID}/access_as_user`],
      });

      const recaptchaToken = await getReCaptchaToken();
      const signInBody = {
        azureAdToken: response.accessToken,
        email: emailInput,
        password: '',
        recaptchaToken,
      };

      await signIn(signInBody);
    } catch (AzureADLoginError) {
      // eslint-disable-next-line no-console
      console.error('Azure AD login failed:', AzureADLoginError);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateEmail(emailInput)) {
      setInvalidEmail(true);
      return;
    }

    if (shouldUseAzureAD(emailInput)) {
      await handleAzureADLogin();
      return;
    }

    const formData = new FormData(event.currentTarget);
    const recaptchaToken = await getReCaptchaToken();
    const signInBody = {
      recaptchaToken,
      email: emailInput,
      password: formData.get('password')?.toString() || '',
    };
    await signIn(signInBody);
  };

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(signInUser(data));
    }
  }, [data]);

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      {/* @ts-ignore */}
      <Error message={error?.data} isOpen={isError} />
      <Grid className={styles.MainGridContainer} container component="main">
        <Grid item xs={12} component={Paper} elevation={6} square>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', lg: '60%' },
              mx: 'auto',
              height: '100vh',
              alignItems: 'center',
              pt: '60px',
            }}
          >
            <img alt="Lidz" src="/logo.webp" height="200" />
            <Typography variant="h4" sx={{ mt: 3 }}>
              Iniciar Sesión
            </Typography>
            <Box
              sx={{
                bgcolor: '#F9FAFD',
                p: 2,
                borderRadius: '20px',
                mt: 2,
                border: '1px solid hsla(210,18%,87%,1)',
                width: { xs: '88%', lg: '420px' },
              }}
              component="form"
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                handleSubmit(e).catch((err) => {
                  // eslint-disable-next-line no-console
                  console.error(err);
                });
              }}
            >
              <TextField
                id="email"
                margin="normal"
                required
                fullWidth
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{ bgcolor: '#fff' }}
                onChange={(e) => {
                  setEmailInput(e.target.value);
                }}
                error={invalidEmail}
                helperText={invalidEmail ? 'Por favor, ingresa un email válido' : ''}
              />
              {!shouldUseAzureAD(emailInput) && (
                <TextField
                  id="password"
                  margin="normal"
                  required
                  fullWidth
                  label="Contraseña"
                  name="password"
                  type="password"
                  autoComplete="password"
                  sx={{ bgcolor: '#fff' }}
                />
              )}
              <SecondaryBtn sx={{ mt: 2, py: 1, mb: 1 }} type="submit" fullWidth variant="contained" color="secondary">
                {shouldUseAzureAD(emailInput) ? 'Iniciar sesión con Microsoft Azure' : 'Iniciar sesión'}
              </SecondaryBtn>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SignIn;
