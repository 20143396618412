import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { useCreateProjectMutation, useGetProjectsQuery } from 'services/api/projects.api';
import { ProjectDraft, PropertyType } from 'services/types/project';

const CreateProject: React.FC = () => {
  const navigate = useNavigate();
  const [createProject, { isLoading, error }] = useCreateProjectMutation();
  const { data: projects } = useGetProjectsQuery(null);

  // Basic project information
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [prompt, setPrompt] = useState('<COMMON_PROMPT>');
  const [commonPrompt, setCommonPrompt] = useState('');
  const [devUrl, setDevUrl] = useState('');
  const [formRequestUrl, setFormRequestUrl] = useState('');
  const [lookerUrl, setLookerUrl] = useState('');
  const [pusherIdentification, setPusherIdentification] = useState('');
  const [scheduleVisitInstructions, setScheduleVisitInstructions] = useState('');

  // Feature flags
  const [hasProperties, setHasProperties] = useState(false);
  const [hasScopeFunctionality, setHasScopeFunctionality] = useState(false);
  const [includeSaveBuyerTypeFunction, setIncludeSaveBuyerTypeFunction] = useState(false);
  const [hasSaveEmployementDetailsFunctionality, setHasSaveEmployementDetailsFunctionality] = useState(false);
  const [skipFinancialDataValidationOnIncome, setSkipFinancialDataValidationOnIncome] = useState(false);
  const [enableCamaraComercioFeature, setEnableCamaraComercioFeature] = useState(false);
  const [enableRegistroCivilFeature, setEnableRegistroCivilFeature] = useState(false);

  // Numeric values
  const [minimumDownPaymentRate, setMinimumDownPaymentRate] = useState<number | null>(null);
  const [minimumPropertyUFPrice, setMinimumPropertyUFPrice] = useState<number | null>(null);
  const [financialScoreWeight, setFinancialScoreWeight] = useState<number | null>(null);
  const [aiScoreWeight, setAiScoreWeight] = useState<number | null>(null);

  // Arrays and objects
  const [propertyTypes, setPropertyTypes] = useState<PropertyType[]>([]);

  // Master Project
  const [masterProjectId, setMasterProjectId] = useState<number | null>(null);

  // Validation
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleAddPropertyType = () => {
    setPropertyTypes([...propertyTypes, { name: '', description: '', ufPrice: null }]);
  };

  const handlePropertyTypeChange = (index: number, field: keyof PropertyType, value: string | number | null) => {
    const newPropertyTypes = [...propertyTypes];
    newPropertyTypes[index] = { ...newPropertyTypes[index], [field]: value };
    setPropertyTypes(newPropertyTypes);
  };

  const validateForm = (): boolean => {
    const errors: string[] = [];

    if (!name) errors.push('El nombre del proyecto es obligatorio');
    if (prompt === '') errors.push('El prompt no puede ser un string vacío');

    setValidationErrors(errors);
    return errors.length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    const projectData: ProjectDraft = {
      name,
      description: description || undefined,
      phoneNumber,
      prompt,
      commonPrompt: commonPrompt || undefined,
      devUrl,
      formRequestUrl: formRequestUrl || undefined,
      lookerUrl: lookerUrl || undefined,
      pusherIdentification: pusherIdentification || undefined,
      scheduleVisitInstructions,
      availableDates: [],
      minimumDownPaymentRate: minimumDownPaymentRate || undefined,
      minimumPropertyUFPrice: minimumPropertyUFPrice || undefined,
      financialScoreWeight,
      aiScoreWeight: aiScoreWeight || undefined,
      masterProjectId,
      hasProperties,
      hasScopeFunctionality,
      includeSaveBuyerTypeFunction,
      hasSaveEmployementDetailsFunctionality,
      skipFinancialDataValidationOnIncome,
      enableCamaraComercioFeature,
      enableRegistroCivilFeature,
      scopes: [],
      propertyTypes: propertyTypes.length > 0 ? propertyTypes : null,
      askClaveUnica: false,
      documentAnalyzerEnabled: false,
      disableResponseDelay: false,
    };

    createProject(projectData)
      .then((result) => {
        if ('data' in result) {
          navigate(`/project/${result.data.id}`);
        }
      })
      .catch((createProjectError) => {
        // eslint-disable-next-line no-console
        console.error('Error creating project:', createProjectError);
      });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Crear Nuevo Proyecto
        </Typography>

        {validationErrors.length > 0 && (
          <Alert severity="error" sx={{ mb: 3 }}>
            <ul>
              {validationErrors.map((validationError) => (
                <li key={validationError}>{validationError}</li>
              ))}
            </ul>
          </Alert>
        )}

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            Error al crear el proyecto. Por favor, inténtalo de nuevo.
          </Alert>
        )}

        <Grid container spacing={3}>
          {/* Basic Information */}
          <Grid item xs={12}>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Información Básica
                </Typography>
                <Divider sx={{ mb: 2 }} />

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Nombre del Proyecto"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Número de teléfono"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Descripción"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      fullWidth
                      multiline
                      rows={3}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="URL de Desarrollo (ignorar, solo para devs)"
                      value={devUrl}
                      onChange={(e) => setDevUrl(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="URL de Solicitud de Formulario Monday"
                      value={formRequestUrl}
                      onChange={(e) => setFormRequestUrl(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="URL de Looker (reporte)"
                      value={lookerUrl}
                      onChange={(e) => setLookerUrl(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Identificación de Pusher (Demochat)"
                      value={pusherIdentification}
                      onChange={(e) => setPusherIdentification(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Proyecto Principal (solo para subproyectos)</InputLabel>
                      <Select
                        value={masterProjectId || ''}
                        onChange={(e) => setMasterProjectId(e.target.value ? Number(e.target.value) : null)}
                        label="Proyecto Principal (solo para subproyectos)"
                      >
                        <MenuItem value="">Ninguno</MenuItem>
                        {projects
                          ?.filter((p) => !p.masterProjectId)
                          .map((project) => (
                            <MenuItem key={project.id} value={project.id}>
                              {project.name} ({project.id})
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* Schedule Visit */}
                <Grid item xs={12}>
                  <TextField
                    label="Instrucciones para Programar Visitas"
                    value={scheduleVisitInstructions}
                    onChange={(e) => setScheduleVisitInstructions(e.target.value)}
                    fullWidth
                    multiline
                    rows={3}
                    margin="normal"
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Prompts */}
          <Grid item xs={12}>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Configuración de Prompts
                </Typography>
                <Divider sx={{ mb: 2 }} />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Prompt"
                      value={prompt}
                      required
                      onChange={(e) => setPrompt(e.target.value)}
                      fullWidth
                      multiline
                      rows={6}
                      margin="normal"
                      helperText="Este prompt define el comportamiento principal del bot para este proyecto. Para ver el prompt bonito se puede hacer después en la vista del proyecto."
                      FormHelperTextProps={{
                        sx: { visibility: 'visible' },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Prompt Común"
                      value={commonPrompt}
                      onChange={(e) => setCommonPrompt(e.target.value)}
                      fullWidth
                      multiline
                      rows={6}
                      margin="normal"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Property Settings */}
          <Grid item xs={12} md={6}>
            <Card sx={{ mb: 3, height: '100%' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Configuración de Propiedades
                </Typography>
                <Divider sx={{ mb: 2 }} />

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Pago mínimo inicial (ej: 0.2)"
                      type="number"
                      value={minimumDownPaymentRate || minimumDownPaymentRate === 0 ? minimumDownPaymentRate : ''}
                      onChange={(e) => setMinimumDownPaymentRate(e.target.value ? Number(e.target.value) : null)}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Precio mínimo de propiedad (UF)"
                      type="number"
                      value={minimumPropertyUFPrice || ''}
                      onChange={(e) => setMinimumPropertyUFPrice(e.target.value ? Number(e.target.value) : null)}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Tipos de Propiedades
                    </Typography>

                    {propertyTypes.map((propertyType, index) => (
                      <Box key={`${index + 1}`} sx={{ mb: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <TextField
                              label="Nombre"
                              value={propertyType.name || ''}
                              onChange={(e) => handlePropertyTypeChange(index, 'name', e.target.value)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              label="Precio UF"
                              type="number"
                              value={propertyType.ufPrice || ''}
                              onChange={(e) =>
                                handlePropertyTypeChange(
                                  index,
                                  'ufPrice',
                                  e.target.value ? Number(e.target.value) : null
                                )
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              label="Descripción"
                              value={propertyType.description || ''}
                              onChange={(e) => handlePropertyTypeChange(index, 'description', e.target.value)}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    ))}

                    <Button variant="outlined" onClick={handleAddPropertyType} sx={{ mt: 1 }}>
                      Agregar Tipo de Propiedad
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Weights & Features */}
          <Grid item xs={12} md={6}>
            <Card sx={{ mb: 3, height: '100%' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Pesos y Características
                </Typography>
                <Divider sx={{ mb: 2 }} />

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Peso de Puntaje Financiero (ej: 0.6)"
                      type="number"
                      value={financialScoreWeight || financialScoreWeight === 0 ? financialScoreWeight : ''}
                      onChange={(e) => setFinancialScoreWeight(e.target.value ? Number(e.target.value) : null)}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Peso de Puntaje AI (ej: 0.2)"
                      type="number"
                      value={aiScoreWeight || aiScoreWeight === 0 ? aiScoreWeight : ''}
                      onChange={(e) => setAiScoreWeight(e.target.value ? Number(e.target.value) : null)}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Características
                    </Typography>

                    <FormControlLabel
                      control={
                        <Checkbox checked={hasProperties} onChange={(e) => setHasProperties(e.target.checked)} />
                      }
                      label="Habilitar propiedades (se crean en otra parte)"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasScopeFunctionality}
                          onChange={(e) => setHasScopeFunctionality(e.target.checked)}
                        />
                      }
                      label="Habilitar funcionalidad de Scope (se configuran después en la vista del proyecto)"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includeSaveBuyerTypeFunction}
                          onChange={(e) => setIncludeSaveBuyerTypeFunction(e.target.checked)}
                        />
                      }
                      label="Habilitar funcionalidad de 'Guardar Tipo de Comprador'"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasSaveEmployementDetailsFunctionality}
                          onChange={(e) => setHasSaveEmployementDetailsFunctionality(e.target.checked)}
                        />
                      }
                      label="Habiltar funcionalidad de 'Guardar Detalles de Empleo'"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={skipFinancialDataValidationOnIncome}
                          onChange={(e) => setSkipFinancialDataValidationOnIncome(e.target.checked)}
                        />
                      }
                      label="Omitir Validación de Datos Financieros en Ingresos"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={enableCamaraComercioFeature}
                          onChange={(e) => setEnableCamaraComercioFeature(e.target.checked)}
                        />
                      }
                      label="Habilitar 'Cámara de Comercio'"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={enableRegistroCivilFeature}
                          onChange={(e) => setEnableRegistroCivilFeature(e.target.checked)}
                        />
                      }
                      label="Habilitar 'Registro Civil'"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Submit Buttons */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button variant="outlined" onClick={() => navigate('/project')}>
                Cancelar
              </Button>
              <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Creando...' : 'Crear Proyecto'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default CreateProject;
