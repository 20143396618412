import { Alert } from 'services/types/alert';
import { AlertType } from 'services/types/project';

import baseApi from './baseApi';

const alertApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAlerts: build.query({
      query: (body: { projectId: string; userFiltered: string; offset: number; limit: number }) => ({
        url: `/alerts?projectId=${body.projectId}&userFiltered=${body.userFiltered}&offset=${body.offset}&limit=${body.limit}`,
        method: 'GET',
      }),
      transformResponse: (response: {
        alerts: Alert[];
        total: number;
        totalPages: number;
      }): {
        alerts: Alert[];
        total: number;
        totalPages: number;
      } => response,
    }),
    postTestAlerts: build.mutation({
      query: (body: { text: string; alertsConfig: AlertType[] }) => ({
        url: '/alerts/test',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { message: string }): { message: string } => response,
    }),
    archiveAlert: build.mutation({
      query: (body: { alertId: number }) => ({
        url: `/alerts/archive/${body.alertId}`,
        method: 'PATCH',
      }),
      transformResponse: (response: { message: string }): { message: string } => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetAlertsQuery, usePostTestAlertsMutation, useArchiveAlertMutation } = alertApi;
