import { useMemo } from 'react';

import { PublicClientApplication, Configuration } from '@azure/msal-browser';

const NODE_ENV = import.meta.env.NODE_ENV || 'development';

const msalConfig: Configuration = {
  auth: {
    clientId: `${import.meta.env.VITE_REACT_APP_AZURE_CLIENT_ID}`,
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_REACT_APP_AZURE_TENANT_ID || ''}`,
    redirectUri: `${import.meta.env.VITE_REACT_APP_AZURE_LIDZ_REDIRECT_URI}`,
  },
};

interface MsalTokenInterface {
  accessToken: string;
}

interface MsalInstanceInterface {
  getActiveAccount: () => void;
  acquireTokenSilent: () => Promise<MsalTokenInterface>;
  initialize: () => Promise<void>;
  handleRedirectPromise: () => Promise<void>;
  loginPopup: () => Promise<MsalTokenInterface>;
  logoutPopup: () => Promise<MsalTokenInterface>;
}

const msalMock: MsalInstanceInterface = {
  getActiveAccount: () => {},
  acquireTokenSilent: () => Promise.resolve({ accessToken: '' }),
  initialize: () => Promise.resolve(),
  handleRedirectPromise: () => Promise.resolve(),
  loginPopup: () => Promise.resolve({ accessToken: '' }),
  logoutPopup: () => Promise.resolve({ accessToken: '' }),
};

export default () => {
  if (NODE_ENV === 'test') return msalMock;
  return useMemo(() => {
    const instance = new PublicClientApplication(msalConfig);
    // eslint-disable-next-line no-console
    instance.initialize().catch(console.error);

    const accounts = instance.getAllAccounts();

    if (accounts.length > 0 && !instance.getActiveAccount()) {
      instance.setActiveAccount(accounts[0]);
    }
    return instance;
  }, []);
};
