import { User } from '../types/user';
import baseApi from './baseApi';

const usersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query({
      query: () => ({
        url: '/admin/users',
      }),
      transformResponse: (response: User[]): User[] => response,
    }),
    getUser: build.query({
      query: (id: number) => ({
        url: `/admin/users/${id}`,
      }),
      transformResponse: (response: User): User => response,
    }),
    updateUser: build.mutation({
      query: ({ id, data }: { id: number; data: Partial<User> }) => ({
        url: `/admin/users/${id}`,
        method: 'PATCH',
        body: data,
      }),
      transformResponse: (response: User): User => response,
    }),
    createUser: build.mutation({
      query: (data: Partial<User>) => ({
        url: '/admin/users',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useGetUsersQuery, useGetUserQuery, useUpdateUserMutation, useCreateUserMutation } = usersApi;
