import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { signInUser, signOutUser } from 'redux/slices/user.slice';
import { AZURE_AD_DOMAINS } from 'utils/azureADConstants';

import useCurrentUser from './useCurrentUser';
import useMsal from './useMsal';

const NODE_ENV = import.meta.env.NODE_ENV || 'development';

export default () => {
  const msalInstance = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const userEmail = currentUser?.email;

  const shouldUseAzureAD = (email: string): boolean => {
    const domain = email.split('@')[1];
    return AZURE_AD_DOMAINS.includes(domain);
  };

  useEffect(() => {
    if (!userEmail || !shouldUseAzureAD(userEmail) || NODE_ENV !== 'test') return;

    async function refreshToken(): Promise<void> {
      try {
        const account = msalInstance.getActiveAccount();
        if (!account) return;

        const response = await msalInstance.acquireTokenSilent({
          scopes: [`api://${import.meta.env.VITE_REACT_APP_AZURE_CLIENT_ID}/access_as_user`],
          account,
          forceRefresh: true,
        });

        if (!currentUser) return;
        dispatch(
          signInUser({
            ...currentUser,
            accessToken: response.accessToken,
          })
        );
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          dispatch(signOutUser());
          navigate('/sign-in');
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const intervalId = setInterval(refreshToken, 45 * 60 * 1000);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [msalInstance, userEmail]);
};
