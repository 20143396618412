import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import LoadingOverlay from 'components/UI/LoadingOverlay';
import Error from 'pages/Error/ErrorAlert';
import { usePutClientMutation } from 'services/api/clients';
import { PutClientWithLeadAttributes } from 'services/types/client';

const NewClient = () => {
  const [putClient, { data: client, isLoading, isError }] = usePutClientMutation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const createOrUpdateClient = async () => {
      const phone = searchParams.get('phone');
      const primarySalesExecutiveEmail = searchParams.get('primarySalesExecutiveEmail');
      const name = searchParams.get('name');

      // Validate required fields
      const missingFields = [];
      if (!phone) missingFields.push('phone');
      if (!name) missingFields.push('name');
      if (!primarySalesExecutiveEmail) missingFields.push('primarySalesExecutiveEmail');

      if (missingFields.length > 0) {
        setErrorMessage(`Missing required fields: ${missingFields.join(', ')}`);
        return;
      }

      const rut = searchParams.get('rut');
      const lastName = searchParams.get('lastName');
      const externalLeadId = searchParams.get('externalLeadId');
      const email = searchParams.get('email');
      const secondarySalesExecutiveEmail = searchParams.get('secondarySalesExecutiveEmail');
      const gender = searchParams.get('gender');
      const dateOfBirth = searchParams.get('dateOfBirth');
      const maritalStatus = searchParams.get('maritalStatus');
      const nationality = searchParams.get('nationality');
      const source = searchParams.get('source');
      const projectOfInterestName = searchParams.get('projectOfInterestName');
      const buyerType = searchParams.get('buyerType');
      const notes = searchParams.get('notes');
      const employmentContractType = searchParams.get('employmentContractType');

      const body: PutClientWithLeadAttributes = {
        name: name!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        phone: phone!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        primarySalesExecutiveEmail: primarySalesExecutiveEmail!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        rut: rut ?? undefined,
        externalLeadId: externalLeadId ?? undefined,
        lastName: lastName ?? undefined,
        email: email ?? undefined,
        secondarySalesExecutiveEmail: secondarySalesExecutiveEmail ?? undefined,
        gender: gender ?? undefined,
        dateOfBirth: dateOfBirth ?? undefined,
        maritalStatus: maritalStatus ?? undefined,
        nationality: nationality ?? undefined,
        source: source ?? undefined,
        projectOfInterestName: projectOfInterestName ?? undefined,
        buyerType: buyerType ?? undefined,
        notes: notes ?? undefined,
        employmentContractType: employmentContractType ?? undefined,
      };

      try {
        const result = await putClient({ body });
        if ('error' in result) {
          const parsedError = result.error as { data: { message: string } };
          setErrorMessage(parsedError.data.message || 'Failed to create client');
        }
      } catch (err) {
        setErrorMessage('An unexpected error occurred');
      }
    };

    createOrUpdateClient(); // eslint-disable-line @typescript-eslint/no-floating-promises
  }, [searchParams]);

  // Handle successful client creation
  useEffect(() => {
    if (client) {
      navigate(`/clients/${client.id}`);
    }
  }, [client, navigate]);

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Error message={errorMessage || 'No se pudo abrir el chat con el cliente'} isOpen={!!errorMessage || isError} />
    </>
  );
};

export default NewClient;
