import { Property, PropertyDraft } from 'services/types/property';

import baseApi from './baseApi';

const propertiesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getProperties: build.query({
      query: (body: { offset: number; limit: number }) => ({
        url: `/admin/properties?offset=${body.offset}&limit=${body.limit}`,
        method: 'GET',
      }),
      transformResponse: (response: {
        properties: Property[];
        total: number;
        totalPages: number;
      }): {
        properties: Property[];
        total: number;
        totalPages: number;
      } => response,
    }),
    getProperty: build.mutation({
      query: (id: number) => ({
        url: `/admin/properties/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Property) => response,
    }),
    updateProperty: build.mutation({
      query: (props: { id: number; body: PropertyDraft }) => ({
        url: `admin/properties/${props.id}`,
        method: 'PATCH',
        body: props.body,
      }),
      transformResponse: (response: Property): Property => response,
    }),
    createProperty: build.mutation({
      query: (props: { body: PropertyDraft }) => ({
        url: 'admin/properties',
        method: 'POST',
        body: props.body,
      }),
      transformResponse: (response: Property): Property => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetPropertiesQuery, useGetPropertyMutation, useUpdatePropertyMutation, useCreatePropertyMutation } =
  propertiesApi;
