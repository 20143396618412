import React from 'react';

import { Box, Select, MenuItem, Typography, Checkbox } from '@mui/material';

import { FilterableUsers } from '../../services/api/user.api';

interface UserSelectorProps {
  filterableUsersData: {
    assignedFilterable: boolean;
    searchableByUser: boolean;
    availableUsers: FilterableUsers[];
  } | null;
  userFilterProps: {
    userFiltered: string;
    setUserFiltered: (value: string) => void;
  } | null;
  onlyContactedProps: {
    onlyContacted: boolean;
    setOnlyContacted: (value: boolean) => void;
  } | null;
}

const UserFiltersComponent: React.FC<UserSelectorProps> = ({
  filterableUsersData,
  userFilterProps,
  onlyContactedProps,
}) => {
  if (!filterableUsersData?.assignedFilterable) {
    return null;
  }

  return (
    <Box ml="3%" display="flex" justifyContent="flex-end" gap={1} flexGrow={1}>
      {filterableUsersData.searchableByUser && userFilterProps && (
        <Select
          value={userFilterProps.userFiltered}
          onChange={(e) => userFilterProps.setUserFiltered(e.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ minWidth: '200px', height: '40px' }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300, // Ajusta la altura máxima según tus necesidades
              },
            },
          }}
        >
          <MenuItem value="">Todos</MenuItem>
          <MenuItem value="unassigned">Sin asignación</MenuItem>
          {filterableUsersData.availableUsers.map((user) => (
            <MenuItem key={user.id} value={`${user.id}`}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      )}
      {onlyContactedProps && (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Typography variant="caption">Solo contactados</Typography>
          <Checkbox
            checked={onlyContactedProps.onlyContacted}
            onChange={(e) => onlyContactedProps.setOnlyContacted(e.target.checked)}
          />
        </Box>
      )}
    </Box>
  );
};

export default UserFiltersComponent;
