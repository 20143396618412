import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContentCopy, MarkChatUnread, SmsFailed } from '@mui/icons-material';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import ErrorIcon from '@mui/icons-material/Error';
import ReplyIcon from '@mui/icons-material/Reply';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridFilterModel, GridSortModel, GridToolbar, GridColDef } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';

import SearchBar from 'components/UI/SearchBar';
import useCurrentUser from 'hooks/useCurrentUser';
import { useGetClientsQuery } from 'services/api/clients';
import { useGetFilterableUsersQuery } from 'services/api/user.api';
import priceFormat from 'services/format/priceFormat';
import { Client } from 'services/types/client';

import UserFiltersComponent from '../../components/common/UserFiltersComponent';

const last24hoursDate = new Date();
last24hoursDate.setDate(last24hoursDate.getDate() - 1);

const USER_ATTRIBUTES: GridColDef<Client>[] = [
  {
    field: 'id',
    headerName: '',
    width: 80,
  },
  {
    field: 'firstName',
    headerName: 'Nombre',
    width: 250,
    renderCell(params) {
      return (
        <div style={{ color: params.row.isTestClient ? 'grey' : undefined, display: 'flex', alignItems: 'center' }}>
          {params.row.messagesCount === 0 && !params.row.isDuplicate ? (
            <SmsFailed sx={{ mr: 1 }} color="error" />
          ) : null}
          {params.row.usersMessagesCount ? <ConnectWithoutContactIcon sx={{ mr: 1 }} /> : null}
          {params.row.isDuplicate ? <ContentCopy sx={{ mr: 1 }} /> : null}
          {params.row.lastMessageRole && params.row.lastMessageRole !== 'assistant' ? (
            <MarkChatUnread sx={{ mr: 1 }} />
          ) : null}
          {params.row.disabledAutomaticResponses ? <SpeakerNotesOffIcon sx={{ mr: 1 }} /> : null}
          {params.row.aiSafetyStatus === 'unsafe' ? <ErrorIcon sx={{ mr: 1 }} color="error" /> : null}
          {params.row.firstName} {params.row.lastName}
          {params.row.clientMessagesCount > 0 ? (
            <ReplyIcon titleAccess="Cliente respondió" fontSize="small" sx={{ ml: 1 }} color="primary" />
          ) : null}
          {params.row.clientMessagesCount > 0 ? (
            <span style={{ color: 'grey', fontSize: '0.7em' }}>(x{params.row.clientMessagesCount})</span>
          ) : null}
        </div>
      );
    },
  },
  {
    field: 'lastName',
    headerName: 'Apellido',
    width: 250,
  },
  {
    field: 'subProject',
    headerName: 'Proyecto',
    width: 250,
    valueGetter: (_value: unknown, row: Client) => row.subProject?.name ?? '-',
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
  },
  {
    field: 'rut',
    headerName: 'RUT',
    width: 140,
  },
  {
    field: 'phone',
    headerName: 'Teléfono',
    width: 180,
  },
  {
    field: 'score',
    headerName: 'Puntaje',
    width: 80,
  },
  {
    field: 'totalSalary',
    headerName: 'Ingresos',
    width: 220,
    sortable: false,
    filterable: false,
    renderCell(params) {
      if (!params.row.salary) return '-';
      let salaryAttribute = priceFormat.format(params.row.salary ?? 0);
      if (params.row.salarySupplement) {
        salaryAttribute = `${salaryAttribute} + ${priceFormat.format(params.row.salarySupplement)}`;
      }
      return salaryAttribute;
    },
  },
  {
    field: 'savingsAmount',
    headerName: 'Ahorros/Pie',
    width: 130,
    renderCell(params) {
      const { savingsAmount } = params.row;
      return savingsAmount ? priceFormat.format(savingsAmount) : '-';
    },
  },
  {
    field: 'quote',
    headerName: 'Cotización',
    width: 110,
  },
  {
    field: 'nationality',
    headerName: 'Nacionalidad',
    width: 110,
  },
  {
    field: 'maritalStatus',
    headerName: 'Estado Civil',
    width: 100,
  },
  {
    field: 'dateOfBirth',
    headerName: 'Fecha Nacimiento',
    width: 140,
  },
  {
    field: 'lastMessageDate',
    headerName: 'Actualización',
    width: 140,
    renderCell(params) {
      const dateValue = new Date(params.row.lastMessageDate as Date);
      if (dateValue > last24hoursDate) return dateValue.toLocaleTimeString('es-CL');
      return `${dateValue.getDate()}/${dateValue.getMonth() + 1} ${dateValue.getHours()}:${dateValue
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
    },
    valueFormatter: (value: Date) => {
      return new Date(value).toLocaleString('es-CL');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Fecha creación',
    width: 140,
    renderCell(params) {
      const dateValue = new Date(params.row.createdAt);
      if (dateValue > last24hoursDate) return dateValue.toLocaleTimeString('es-CL');
      return `${dateValue.getDate()}/${dateValue.getMonth() + 1} ${dateValue.getHours()}:${dateValue
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
    },
    valueFormatter: (value: Date) => {
      return new Date(value).toLocaleString('es-CL');
    },
  },
  {
    field: 'source',
    headerName: 'Origen',
    width: 180,
  },
  {
    field: 'messagesCount',
    headerName: 'Mensajes',
    width: 90,
  },
  {
    field: 'usersMessagesCount',
    headerName: 'Mensajes de Usuarios',
    width: 140,
  },
  {
    field: 'executiveMail',
    headerName: 'Ejecutivo',
    width: 200,
    valueGetter: (_params, row) => row?.executiveInformation?.executiveEmail || '',
  },
  {
    field: 'notes',
    headerName: 'Comentario',
    width: 200,
  },
];

const ADMIN_USER_ATTRIBUTES: GridColDef<Client>[] = [
  {
    field: 'projectId',
    headerName: 'ID Inmobiliaria',
    width: 80,
  },
  {
    field: 'subProjectId',
    headerName: 'ID Proyecto Asignado',
    width: 80,
  },
  {
    field: 'inputChannel',
    headerName: 'Input Channel',
    width: 140,
    sortable: false,
    filterable: false,
  },
  {
    field: 'imagesSent',
    headerName: 'Imágenes',
    width: 90,
  },
  {
    field: 'followUpsSent',
    headerName: 'Follow Ups',
    width: 100,
  },
  {
    field: 'keepAliveMessagesSent',
    headerName: 'Keep Alive',
    width: 100,
  },
  {
    field: 'templatesSent',
    headerName: 'Templates',
    width: 100,
  },
  {
    field: 'isTestClient',
    headerName: 'Test',
    width: 50,
    valueFormatter: (value: boolean) => (value ? 'Si' : ''),
  },
  {
    field: 'disabledAutomaticResponses',
    headerName: 'Sin IA',
    width: 60,
    valueFormatter: (value: boolean) => (value ? 'Si' : ''),
  },
  {
    field: 'endReason',
    headerName: 'Razón término',
    width: 200,
  },
  {
    field: 'topics',
    headerName: 'Temas',
    width: 200,
    valueFormatter: (value: string[]) => value?.join(', '),
  },
  {
    field: 'floidRequestsCount',
    headerName: 'Floid',
    width: 60,
  },
  {
    field: 'floidRequestsDetails',
    headerName: 'Floid detalles',
    width: 300,
    valueFormatter: (value: string) =>
      value
        ?.replace(/REGISTRO_CIVIL/g, 'RegCivil')
        .replace(/CAMARA_COMERCIO/g, 'CCS')
        .replace(/: 200/g, '')
        .replace(/200/g, 'OK')
        .replace(/400/g, 'ERR'),
  },
  {
    field: 'adminNotes',
    headerName: 'Notas Administrador',
    width: 400,
  },
  { field: 'clientMessagesCount', headerName: 'Mensajes Cliente', width: 140 },
  {
    field: 'charge',
    headerName: 'Cobrar',
    width: 60,
    valueFormatter: (value: boolean) => (value ? 'Si' : 'No'),
  },
];

interface PaginationModel {
  page: number;
  pageSize: number;
  pageTotal: number;
  total: number;
}
const Clients: FC = () => {
  const currentUser = useCurrentUser();

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const queryFilterModel = queryParams.get('filterModel');
  const queryPaginationModel = queryParams.get('paginationModel');
  const querySortModel = queryParams.get('sortModel');
  const querySearchTerm = queryParams.get('searchTerm');
  const queryProjectId = queryParams.get('projectId');

  const highlightedClientId = queryParams.get('highlightedClientId') || '';

  const [searchTerm, setSearchTerm] = useState<string>(querySearchTerm || '');
  const [projectId, setProjectId] = useState<string>(queryProjectId || '');
  const [filterModel, setFilterModel] = useState<GridFilterModel>(
    queryFilterModel ? (JSON.parse(queryFilterModel) as GridFilterModel) : { items: [] }
  );
  const parsedQueryPaginationModel = queryPaginationModel
    ? (JSON.parse(queryPaginationModel) as Partial<PaginationModel>)
    : null;
  const [paginationModel, setPaginationModel] = useState<Partial<PaginationModel>>(
    parsedQueryPaginationModel &&
      (parsedQueryPaginationModel.pageSize ?? 100) <= 100 &&
      (parsedQueryPaginationModel.page ?? 0) >= 0
      ? parsedQueryPaginationModel
      : {
          page: 0,
          pageSize: 100,
        }
  );
  const [sortModel, setSortModel] = useState<GridSortModel>(
    querySortModel ? (JSON.parse(querySortModel) as GridSortModel) : []
  );
  const [userFiltered, setUserFiltered] = useState<string>('');
  const [onlyContacted, setOnlyContacted] = useState<boolean>(false);

  const { data, refetch } = useGetClientsQuery({
    limit: paginationModel.pageSize || 100, // Use 100 as the default
    offset: paginationModel.page && paginationModel.pageSize ? paginationModel.page * paginationModel.pageSize : 0,
    sortModel: querySortModel || '',
    filterModel: queryFilterModel || '',
    searchTerm,
    projectId,
    userFiltered,
    onlyContacted,
  });

  const { data: filterableUsersData, refetch: userRefetch } = useGetFilterableUsersQuery({});

  const scrollToHighlightedRow = () => {
    if (!data?.clients) return;
    const rowIndex = data.clients.findIndex((client) => client.id.toString() === highlightedClientId);
    if (rowIndex < 5 || data.clients.length < 5) return;
    document.querySelector('.MuiDataGrid-virtualScroller')?.scrollTo({ top: 52 * (rowIndex - 3), behavior: 'smooth' });
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToHighlightedRow();
    }, 400);
  }, []);

  useEffect(() => {
    scrollToHighlightedRow();
  }, [data?.clients]);

  const columns = currentUser?.isAdmin ? USER_ATTRIBUTES.concat(ADMIN_USER_ATTRIBUTES) : USER_ATTRIBUTES;

  const navigateToClientId = (id: number, newPage = false) => {
    queryParams.set('highlightedClientId', id.toString());
    navigate(`?${queryParams.toString()}`, { replace: true });
    if (newPage) {
      window.open(`/clients/${id}`, '_blank');
      return;
    }
    navigate(`/clients/${id}`);
  };

  useEffect(() => {
    queryParams.delete('paginationModel');
    // save pagination in query params
    queryParams.set('paginationModel', JSON.stringify(paginationModel));
    navigate(`?${queryParams.toString()}`);

    queryParams.delete('filterModel');
    // save filters in query params
    if (filterModel.items.length > 0) {
      queryParams.set('filterModel', JSON.stringify(filterModel));
      navigate(`?${queryParams.toString()}`);
    }

    queryParams.delete('sortModel');
    // save sort in query params
    if (sortModel.length > 0) {
      queryParams.set('sortModel', JSON.stringify(sortModel));
      navigate(`?${queryParams.toString()}`);
    }

    queryParams.delete('searchTerm');
    // save search term in query params
    if (searchTerm) {
      queryParams.set('searchTerm', searchTerm);
      navigate(`?${queryParams.toString()}`);
    }

    queryParams.delete('projectId');
    // save projectId in query params
    if (projectId) {
      queryParams.set('projectId', projectId);
      navigate(`?${queryParams.toString()}`);
    }

    refetch().catch((e) => console.error(e)); // eslint-disable-line no-console
    setPaginationModel({ ...paginationModel, pageTotal: data?.totalPages || 1, total: data?.total || 0 });
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    sortModel,
    filterModel,
    searchTerm,
    projectId,
    userFiltered,
    onlyContacted,
  ]);

  useEffect(() => {
    userRefetch().catch((e) => console.error(e)); // eslint-disable-line no-console
  }, [projectId]);

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        mt={1}
        flexDirection={{ xs: 'column', md: 'row' }}
        ml={{ xs: '60px', lg: 0 }}
      >
        <Typography variant="h3" mt={2} mb={2} width="100%">
          Lista de posibles clientes
        </Typography>
        <SearchBar
          searchTerm={searchTerm}
          onSearchTermChange={setSearchTerm}
          projectId={projectId}
          onProjectIdChange={setProjectId}
        />
      </Box>
      <Box display="flex" alignItems="center" p={1} justifyContent="flex-start" width="100%" overflow="scroll">
        <ReplyIcon fontSize="small" sx={{ ml: '10px', mr: '4px' }} color="primary" />
        <Typography variant="caption">Mensajes del cliente</Typography>
        <SpeakerNotesOffIcon fontSize="small" sx={{ ml: '10px', mr: '4px' }} />
        <Typography variant="caption">Sin IA</Typography>
        <MarkChatUnread fontSize="small" sx={{ ml: '10px', mr: '4px' }} />
        <Typography variant="caption">Por responder</Typography>
        <ErrorIcon fontSize="small" sx={{ ml: '10px', mr: '4px' }} color="error" />
        <Typography variant="caption">Inseguro</Typography>
        <SmsFailed fontSize="small" sx={{ ml: '10px', mr: '4px' }} color="error" />
        <Typography variant="caption">Sin mensajes</Typography>
        <ContentCopy fontSize="small" sx={{ ml: '10px', mr: '4px' }} />
        <Typography variant="caption">Cliente duplicado</Typography>
        <ConnectWithoutContactIcon fontSize="small" sx={{ ml: '10px', mr: '4px' }} />
        <Typography variant="caption">Contactado por usuarios</Typography>
        <UserFiltersComponent
          filterableUsersData={filterableUsersData || null}
          userFilterProps={{ userFiltered, setUserFiltered }}
          onlyContactedProps={{ onlyContacted, setOnlyContacted }}
        />
      </Box>
      <Box style={{ width: '100%' }}>
        <Box
          sx={{
            backgroundColor: 'White',
            borderRadius: 4,
            p: 2,
          }}
        >
          <DataGrid
            rows={data?.clients || []}
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  lastName: false,
                },
              },
            }}
            rowCount={data?.total || 0}
            slots={{
              toolbar: GridToolbar,
            }}
            disableDensitySelector
            pageSizeOptions={[100]}
            slotProps={{
              toolbar: {
                csvOptions: { delimiter: ';', utf8WithBom: true, fileName: 'ClientesLidz', allColumns: true },
                printOptions: { disableToolbarButton: true },
              },
            }}
            onRowClick={(params, event) => {
              navigateToClientId((params.row as Client).id, event.ctrlKey || event.metaKey);
            }}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              color: 'Black',
              height: {
                xs: currentUser?.isAdmin ? 'calc(100vh - 390px)' : 'calc(100vh - 310px)',
                md: 'calc(100vh - 160px)',
              },
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-row.Mui-selected': {
                backgroundColor: '#e8f0fe',
              },
              border: 'none',
            }}
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            paginationModel={{ page: paginationModel.page || 0, pageSize: paginationModel.pageSize || 100 }}
            onPaginationModelChange={(params) => {
              setPaginationModel({ ...paginationModel, page: params.page, pageSize: params.pageSize });
            }}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            getRowClassName={(params) => (params.row.id.toString() === highlightedClientId ? 'Mui-selected' : '')}
          />
        </Box>
      </Box>
    </>
  );
};

export default Clients;
