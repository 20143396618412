import React, { FC } from 'react';

import {
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { DataGrid, GridToolbar, GridColDef } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';

import { EditIcon } from 'assets/icons';
import PrimaryBtn from 'components/UI/Buttons';
import SectionHeader from 'components/layout/SectionHeader';
import { useGetUsersQuery, useUpdateUserMutation, useCreateUserMutation } from 'services/api/adminUsers.api';
import { useGetProjectsQuery } from 'services/api/projects.api';
import { Project } from 'services/types/project';
import { User } from 'services/types/user';

const getUserAttributes = (projects: Project[], onEditClick: (user: User) => void): GridColDef<User>[] => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" justifyContent="space-between" height="100%">
          <Typography>{params.row.id}</Typography>
          <IconButton size="small" sx={{ ml: 1 }} onClick={() => onEditClick(params.row)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
    },
    {
      field: 'name',
      headerName: 'Nombre',
      width: 300,
    },
    {
      field: 'projectId',
      headerName: 'Empresa',
      width: 230,
      valueFormatter: (value: number | null): string | number =>
        value ? `${projects.find((project) => project.id === value)?.name} (${value})` : '(admin)',
    },
    {
      field: 'isAdmin',
      headerName: '¿Es admin?',
      width: 100,
      valueFormatter: (value: boolean) => (value ? 'Si' : 'No'),
    },
    {
      field: 'subProjectId',
      headerName: 'Subproyecto',
      width: 100,
    },
    {
      field: 'subProjectIds',
      headerName: 'Subproyecto(s)',
      width: 600,
      valueFormatter: (value: string | null): string | number =>
        `${projects
          .filter((project) => value?.split(',').map(Number).includes(project.id))
          .map((project) => `${project.name} (${project.id})`)
          .join(', ')}`,
    },
    {
      field: 'isTestUser',
      headerName: '¿Es test?',
      width: 100,
      valueFormatter: (value: boolean) => (value ? 'Si' : 'No'),
    },
  ];
};

interface EditUserModalProps {
  open: boolean;
  onClose: () => void;
  user: User | null;
  projects: Project[];
  onSave: (data: { email: string; name: string; projectId: number; subProjectIds: string; password?: string }) => void;
}

const EditUserModal: FC<EditUserModalProps> = ({ open, onClose, user, projects, onSave }) => {
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [projectId, setProjectId] = React.useState<number>(0);
  const [subProjectIds, setSubProjectIds] = React.useState<number[]>([]);
  const [password, setPassword] = React.useState('');

  React.useEffect(() => {
    if (user) {
      setEmail(user.email);
      setName(user.name);
      setProjectId(user.projectId || 0);
      setSubProjectIds(user.subProjectIds ? user.subProjectIds?.split(',').map(Number) : []);
    }
  }, [user]);

  const closeModal = () => {
    onClose();
    setEmail('');
    setName('');
    setProjectId(0);
    setPassword('');
    setSubProjectIds([]);
  };

  const handleSubmit = () => {
    if (!projectId) return;
    onSave({
      email,
      name,
      projectId,
      subProjectIds: subProjectIds.join(','),
      password: password === '' ? undefined : password,
    });
    closeModal();
  };

  return (
    <Dialog open={open} onClose={closeModal} maxWidth="sm" fullWidth>
      <DialogTitle>Información Usuario</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />
          <TextField label="Nombre" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
          <TextField
            label="Contraseña (solo si se quiere cambiar o para usuarios nuevos)"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel>Empresa</InputLabel>
            <Select value={projectId} onChange={(e) => setProjectId(e.target.value as number)} label="Empresa">
              <MenuItem value={0}>-</MenuItem>
              {projects
                .filter((project) => project.masterProjectId === null)
                .map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name} ({project.id})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth disabled={projectId === 0}>
            <InputLabel>Subproyecto(s)</InputLabel>
            <Select
              multiple
              value={subProjectIds}
              onChange={(e) => setSubProjectIds(e.target.value as number[])}
              label="Subproyecto(s)"
            >
              {projects
                .filter((project) => project.masterProjectId === projectId)
                .map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name} ({project.id})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!projectId || (password !== '' && password.length < 8)}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UsersTable: FC = () => {
  const { data: users, refetch } = useGetUsersQuery(null);
  const { data: projects } = useGetProjectsQuery(null);
  const [selectedUserId, setSelectedUserId] = React.useState<number | null>(null);
  const [createMode, setCreateMode] = React.useState(false);
  const selectedUser = users?.find((user) => user.id === selectedUserId) || null;
  const [updateUser] = useUpdateUserMutation();
  const [createUser] = useCreateUserMutation();

  const handleEditClick = (user: User) => {
    setCreateMode(false);
    setSelectedUserId(user.id);
  };

  const handleCreateClick = () => {
    setCreateMode(true);
    setSelectedUserId(null);
  };

  const handleCloseModal = () => {
    setSelectedUserId(null);
    setCreateMode(false);
  };

  const handleSave = (data: {
    email: string;
    name: string;
    projectId: number;
    subProjectIds: string;
    password?: string;
  }) => {
    if (createMode) {
      createUser(data)
        .then(() => {
          refetch().catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
          });
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    } else if (selectedUserId) {
      updateUser({ id: selectedUserId, data })
        .then(() => {
          refetch().catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
          });
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  };

  return (
    <Box style={{ width: '100%' }}>
      <SectionHeader
        text="Lista de Usuarios"
        button={<PrimaryBtn onClick={handleCreateClick}>Nuevo Usuario</PrimaryBtn>}
      />
      <Box
        sx={{
          backgroundColor: 'White',
          borderRadius: 4,
          p: 2,
        }}
      >
        <DataGrid
          rows={users || []}
          columns={getUserAttributes(projects || [], handleEditClick)}
          slots={{
            toolbar: GridToolbar,
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            color: 'Black',
            height: 'calc(100vh - 150px)',
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: '#e8f0fe',
            },
            border: 'none',
          }}
        />
      </Box>
      <EditUserModal
        open={!!selectedUserId || createMode}
        onClose={handleCloseModal}
        user={selectedUser}
        projects={projects || []}
        onSave={handleSave}
      />
    </Box>
  );
};

export default UsersTable;
