import { FunctionComponent } from 'react';

import Alerts from 'pages/Alerts';
import Analyses from 'pages/Analyses';
import Analysis from 'pages/Analysis';
import ClaveUnicaWhatsApp from 'pages/ClaveUnicaWhatsApp';
import Client from 'pages/Client';
import ClientQuestions from 'pages/ClientQuestions';
// import Admin from 'pages/Admin';
// import WhatsAppChat from 'pages/Admin/components/WhatsAppChat';
import Clients from 'pages/Clients';
import Contact from 'pages/Contact';
import Dashboard from 'pages/Dashboard';
import DbReader from 'pages/DbReader';
import DemoChat from 'pages/DemoChat';
import NewDemoChat from 'pages/DemoChat/NewChat';
import ErrorPage from 'pages/Error/ErrorPage';
import Looker from 'pages/Looker';
import NewClient from 'pages/NewClient';
import Playground from 'pages/Playground';
import PortalInmobiliarioMock from 'pages/PortalInmobiliarioMock';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Profile from 'pages/Profile';
import Project from 'pages/Project';
import CreateProject from 'pages/Project/CreateProject';
import PromptSections from 'pages/PromptSections';
import Properties from 'pages/Properties';
import Property from 'pages/Property';
import PublicChat from 'pages/PublicChat';
// import Article from 'pages/Blog/Article';
import SignIn from 'pages/SignIn';
import TermsConditions from 'pages/TermsConditions';
import Users from 'pages/Users';

export type Route = {
  title: string;
  path: string;
  regex?: RegExp;
  component: FunctionComponent;
  private: boolean;
  index: boolean;
  noLayout?: boolean;
};

const routes: { [key: string]: Route } = {
  '/sign-up': {
    title: 'Crear Usuario',
    path: '/sign-up',
    component: SignIn,
    private: false,
    index: false,
  },
  '/sign-in': {
    title: 'Iniciar Sesión',
    path: '/sign-in',
    component: SignIn,
    private: false,
    index: false,
  },
  '/contact': {
    title: 'Contacto',
    path: '/contact',
    component: Contact,
    private: false,
    index: false,
  },
  '/users': {
    title: 'Usuarios',
    path: '/users',
    component: Users,
    private: true,
    index: false,
  },
  '/properties': {
    title: 'Propiedades',
    path: '/properties',
    component: Properties,
    private: true,
    index: false,
  },
  '/properties/:id': {
    title: 'Propiedad',
    path: '/properties/:id',
    regex: /\/properties\/(\d+|new)/,
    component: Property,
    private: true,
    index: false,
  },
  '/analysis': {
    title: 'Análisis',
    path: '/analysis',
    component: Analyses,
    private: true,
    index: false,
  },
  '/analysis/:analysisId': {
    title: 'Análisis',
    path: '/analysis/:analysisId',
    regex: /\/analysis\/\d+/,
    component: Analysis,
    private: true,
    index: false,
  },
  '/profile': {
    title: 'Perfil',
    path: '/profile',
    component: Profile,
    private: true,
    index: false,
  },
  '/project': {
    title: 'Proyecto',
    path: '/project',
    component: Project,
    private: true,
    index: false,
  },
  '/project/create': {
    title: 'Crear Proyecto',
    path: '/project/create',
    component: CreateProject,
    private: true,
    index: false,
  },
  '/project/:projectId': {
    title: 'Proyecto',
    path: '/project/:projectId',
    regex: /\/project\/\d+/,
    component: Project,
    private: true,
    index: false,
  },
  '/clients': {
    title: 'Clientes',
    path: '/clients',
    component: Clients,
    private: true,
    index: false,
  },
  '/clients/new': {
    title: 'Nuevo Cliente',
    path: '/clients/new',
    component: NewClient,
    private: true,
    index: false,
  },
  '/clients/:id': {
    title: 'Cliente',
    path: '/clients/:id',
    regex: /\/clients\/\d+/,
    component: Client,
    private: true,
    index: false,
  },
  '/portal-inmobiliario': {
    title: 'Portal Inmobiliario',
    path: '/portal-inmobiliario',
    component: PortalInmobiliarioMock,
    private: false,
    index: false,
  },
  '/': {
    title: 'Bienvenido',
    path: '/',
    component: Dashboard,
    private: true,
    index: false,
  },
  // '/admin': {
  //   title: 'Administrador',
  //   path: '/admin',
  //   component: Admin,
  //   private: true,
  //   index: false,
  // },
  '/admin/projects': {
    title: 'Proyectos',
    path: '/admin/projects',
    component: Project,
    private: true,
    index: false,
  },
  '/playground': {
    title: 'Playground',
    path: '/playground/:messageId',
    regex: /\/playground\/\d+/,
    component: Playground,
    private: true,
    index: false,
    noLayout: true,
  },
  // '/admin/clients': {
  //   title: 'Clients',
  //   path: '/admin/chat',
  //   component: WhatsAppChat,
  //   private: true,
  //   index: false,
  // },
  '/clave-unica-whatsapp': {
    title: 'Clave Única WhatsApp',
    path: '/clave-unica-whatsapp',
    component: ClaveUnicaWhatsApp,
    private: false,
    index: false,
  },
  '/db-reader': {
    title: 'Analizador de Base de Datos',
    path: '/db-reader',
    component: DbReader,
    private: true,
    index: false,
  },
  '/questions': {
    title: 'Preguntas de Clientes',
    path: '/questions',
    component: ClientQuestions,
    private: true,
    index: false,
  },
  '/demochat': {
    title: 'Demo Chat',
    path: '/demochat',
    component: DemoChat,
    private: false,
    index: false,
    noLayout: true,
  },
  '/demochat/:companyIdentifier': {
    title: 'Demo Chat',
    path: '/demochat/:companyIdentifier',
    component: DemoChat,
    private: false,
    index: false,
    noLayout: true,
  },
  '/demochat/:companyIdentifier/newchat': {
    title: 'New Demo Chat',
    path: '/demochat/:companyIdentifier/newchat',
    component: NewDemoChat,
    private: false,
    index: false,
    noLayout: true,
  },
  '/publicChat': {
    title: 'Public Chat',
    path: '/publicChat',
    component: PublicChat,
    private: false,
    index: false,
    noLayout: true,
  },
  '/terms-conditions': {
    title: 'Términos y Condiciones',
    path: '/terms-conditions',
    component: TermsConditions,
    private: false,
    index: false,
  },
  '/privacy-policy': {
    title: 'Política de Privacidad',
    path: '/privacy-policy',
    component: PrivacyPolicy,
    private: false,
    index: false,
  },
  '/information': {
    title: 'Información',
    path: '/information',
    component: PromptSections,
    private: true,
    index: false,
  },
  '/alerts': {
    title: 'Alertas',
    path: '/alerts',
    component: Alerts,
    private: true,
    index: false,
  },
  '/looker': {
    title: 'Reporte',
    path: '/looker',
    component: Looker,
    private: true,
    index: false,
  },
  '/looker/:projectId': {
    title: 'Reporte',
    path: '/looker/:projectId',
    component: Looker,
    private: true,
    index: false,
  },
  // this path must be the last one
  error: {
    title: 'Error',
    path: '*',
    component: ErrorPage,
    private: true,
    index: false,
  },
};

export default routes;
