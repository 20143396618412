import React, { FC } from 'react';

import { Provider, ErrorBoundary } from '@rollbar/react';
import Layout from 'components/layout';
import config from 'config';
import useRefreshMsalToken from 'hooks/useRefreshMsalToken';
import AppRoutes from 'routes';

const rollbarConfig = {
  accessToken: config.rollbarAccessToken,
  environment: config.environment,
  enabled: config.environment !== 'development',
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
};

const App: FC = () => {
  useRefreshMsalToken();
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <Layout>
          <AppRoutes />
        </Layout>
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
